import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

// Old text for the body, under the h1
/*  
                    <p>WE ARE A GROUP OF WRITERS WORKING ACROSS A VARIETY OF GENRES AND MEDIA 
                    WHO TRY TO SUPPORT EACH OTHER WHEN IT'S NOT TOO MUCH TROUBLE.</p>
*/

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <span className="icon fa-book"></span>
        </div>
        <div className="content">
            <div className="inner">
                <h1>New Haven Speculative Fiction Workshop</h1>
                    <p>We are a group of speculative fiction writers who meet <br />
                       periodically to workshop stories and write together. </p>
            </div>
        </div>
        <nav>
            <ul>
                <li><Link to="/introduction">About</Link></li>
                <li><Link to="/members">Members</Link></li>
                <li><Link to="/rules">Rules</Link></li>
                <li><Link to="/contact">Contact</Link></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
